import React from 'react';
import { useLocation } from '@reach/router';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';

import { PortableTextBlock } from '../sanity/portableTextBlock';

export const BlogContent = ({ content }) => {
  const { href } = useLocation();
  const breakpoints = useBreakpoint();

  return (
    <div className='grid grid-cols-14 relative mt-32 mb-24 md:mb-56 '>
      <div className='col-start-2 lg:col-start-3 col-end-14 md:col-end-11'>
        {content && (
          <PortableTextBlock
            className='flex flex-col space-y-6 font-sans'
            text={content}
          />
        )}
      </div>
      <div className='col-start-2 md:col-start-12 col-end-14 mt-8 md:mt-0'>
        <div className='text-[18px] sticky top-12'>
          <p className='mb-4 md:mb-6'>Share article:</p>
          <div className='flex flex-row md:flex-col items-center md:items-start space-x-2 md:space-x-0 space-y-0'>
            <FacebookShareButton
              url={href}
              style={{
                textDecoration: 'underline',
                textUnderlineOffset: '3px',
              }}>
              {breakpoints.sm ? (
                <FacebookIcon size={32} round={true} />
              ) : (
                'Facebook'
              )}
            </FacebookShareButton>
            <EmailShareButton
              url={href}
              style={{
                textDecoration: 'underline',
                textUnderlineOffset: '3px',
              }}>
              {breakpoints.sm ? <EmailIcon size={32} round={true} /> : 'Email'}
            </EmailShareButton>
            <LinkedinShareButton
              url={href}
              style={{
                textDecoration: 'underline',
                textUnderlineOffset: '3px',
              }}>
              {breakpoints.sm ? (
                <LinkedinIcon size={32} round={true} />
              ) : (
                'LinkedIn'
              )}
            </LinkedinShareButton>
          </div>
        </div>
      </div>
    </div>
  );
};
