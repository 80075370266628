import React, { Fragment } from 'react';
import PartnersCarousel from '../components/blockZone/blocks/partnersCarousel';
import { graphql } from 'gatsby';
import { PageMeta } from '../components/pageMeta';
import { BlogBanner } from '../components/blog/blogBanner';
import { BlogContent } from '../components/blog/blogContent';
import { BlogCarousel } from '../components/blockZone/blocks/blogCarousel';
import { useScrollContext } from '../components/layout';

const BlogTemplate = ({ data: { blog } }) => {
  const { scrollDefined } = useScrollContext();
  const { meta } = blog || {};

  return (
    <Fragment>
      <PageMeta {...meta} />
      {scrollDefined && (
        <Fragment>
          <BlogBanner {...blog} />
          <BlogContent {...blog} />
          <BlogCarousel />
          <PartnersCarousel heading='Proudly working with' />
        </Fragment>
      )}
    </Fragment>
  );
};

export default BlogTemplate;

export const blogQuery = graphql`
  query standardBlogPostQuery($slug: String!) {
    blog: sanityBlogPost(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
      }
      title
      slug {
        current
      }
      featuredImage {
        ...ImageWithPreview
      }
      excerpt
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`;
