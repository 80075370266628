import React, { useEffect, useRef } from 'react';
import { SplitText } from 'gsap/SplitText';
import { gsap } from 'gsap';
import Image from 'gatsby-plugin-sanity-image';

import ArrowDown from '../../svg/arrow-down.svg';

export const BlogBanner = ({ title, featuredImage, excerpt }) => {
  const blogHeadlineRef = useRef(null);
  const blogParentRef = useRef(null);
  const blogTextRef = useRef(null);

  useEffect(() => {
    const mySplitText = new SplitText(blogTextRef.current, { type: 'lines' });

    gsap.from(mySplitText.lines, {
      duration: 1.8,
      yPercent: 40,
      opacity: 0,
      stagger: 0.2,
      delay: 2.5,
      ease: 'power4.out',
      scrollTrigger: {
        trigger: blogParentRef.current,
        start: 'top center+=100',
        toggleActions: 'play none none reverse',
      },
    });

    gsap.from(blogHeadlineRef.current, {
      opacity: 0,
      duration: 0.8,
      delay: 2,
      scrollTrigger: {
        trigger: blogParentRef.current,
        start: 'top center+=100',
        toggleActions: 'play none none reverse',
      },
    });
  }, []);

  return (
    <div
      ref={blogParentRef}
      className='h-screen relative px-gutter flex items-center justify-center'>
      {featuredImage && (
        <Image
          {...featuredImage}
          className='absolute object-cover w-full h-full'
        />
      )}
      <div className='absolute w-full h-full bg-black bg-opacity-60' />
      <div className='px-gutter lg:px-0 flex items-center justify-center flex-col gap-y-9 absolute w-full h-full text-center max-w-4xl'>
        <h1 ref={blogHeadlineRef} className='text-4xl md:text-6xl'>
          {title}
        </h1>
        <p ref={blogTextRef} className='text-lg md:text-2xl font-light'>
          {excerpt}
        </p>
      </div>

      <div className='absolute bottom-5 bounce-y'>
        <ArrowDown />
      </div>
    </div>
  );
};
